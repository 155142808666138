export default {
    'left': {
        path: '0,0 360,678 1920,678 1920,0',
        class: 'left',
        animate: 'bounceInRight'
    },
    'right': {
        path: '360,0 0,678 1920,678 1920,0',
        class: 'right',
        animate: 'bounceInLeft'
    },
    'reversal-left': {
        path: '0,0 1920,0 1560,678 0,678',
        class: 'left',
        animate: 'bounceInRight'
    },
    'reversal-right': {
        path: '0,0 1560,0 1920,678 0,678',
        class: 'right',
        animate: 'bounceInLeft'
    },
};
