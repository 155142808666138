import imageOption from '../option/image';
import require_image from "$utils/images/require_image";
export default [
    {
        label: '商城类APP',
        content: '商城手机客户端，让购物更简单，百万商品，低价抢购，厂家直供，砍价团购让您购物无忧!聊天，小视频，分销，跨境电商，对接客户自己的物流系统，社区导购社区分享，让您购物不单调!',
        images: [
            require_image('images/cash/1/1.png', imageOption),
            require_image('images/cash/1/2.png', imageOption)
        ]
    },
    {
        label: '外卖跑腿类APP',
        content: '随着互联网的普及，人们越来越享受在便捷的生活中，足不出户一览无余，快速订餐。轻松下单极速送达-键催单，催单退单由你决定，订单追踪，配送实时定位，在线便捷支付。',
        images: [
            require_image('images/cash/2/1.png', imageOption),
            require_image('images/cash/2/2.png', imageOption)
        ]
    },
    {
        label: '1V1直播类APP',
        content: '所有的主播都通过严格的人工真人认证，实名制更靠谱，在你脱单的道路上保驾护航。高效率，保障每一位用户权益，营造温馨，和谐的聊天氛围。系统安全加密，保障双方的聊天隐私。',
        images: [
            require_image('images/cash/3/1.png', imageOption),
            require_image('images/cash/3/2.png', imageOption)
        ]
    },
    {
        label: '资讯头条类APP',
        content: '跟踪相关动态，让你轻松、高效、及时地获取自己关心的优质信息。了解\n' +
            '来自科技、游戏、运动、旅游、美食、电影、时尚、家居等多方面的尖端\n' +
            '生活资讯及即时新闻报道。',
        images: [
            require_image('images/cash/4/1.png', imageOption),
            require_image('images/cash/4/2.png', imageOption)
        ]
    },
    {
        label: '社交类APP',
        content: '面向社会公众的即时通讯平台，支持跨通信运营商、跨操作系统平台通过网络快速发送免费语音短信、视频、图片等(需消耗少量网络流量)，也可以使用通过共享流媒体内容的资料和基于位置的社交软件!',
        images: [
            require_image('images/cash/5/1.png', imageOption),
            require_image('images/cash/5/2.png', imageOption)
        ]
    },
];
