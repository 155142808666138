var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from "vue-property-decorator";
import data from '../data/data';
import layoutPhone from '$layout/layout-phone';
import html from '$utils/tool/html';
import { vAnimateTiming } from '$components/index';
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.typeList = ['left', 'right', 'reversal-left', 'reversal-right'];
    }
};
__decorate([
    Prop({
        type: Array,
        default: function () {
            return ['#F8FBFF', '#257DFF'];
        }
    })
], default_1.prototype, "colors", void 0);
__decorate([
    Prop({
        type: String,
        default: ''
    })
], default_1.prototype, "color", void 0);
__decorate([
    Prop({
        type: Number,
        default: 4
    })
], default_1.prototype, "nth", void 0);
__decorate([
    Prop({
        type: Number,
        default: 0
    })
], default_1.prototype, "index", void 0);
__decorate([
    Prop({
        type: Object,
        default: function () { return {}; }
    })
], default_1.prototype, "item", void 0);
default_1 = __decorate([
    Component({
        components: {
            layoutPhone,
            vAnimateTiming
        },
        computed: {
            direction() {
                let item = {
                    // @ts-ignore
                    ...data[this.type]
                };
                if (this.color) {
                    item.color = this.color;
                }
                else if (!item.color) {
                    item.color = this.colors[this.index % this.colors.length];
                }
                return item;
            },
            type() {
                return this.typeList[this.index % this.nth];
            },
            content() {
                return html.replaceBr((this.$store.state.config.mobile ? this.item.mobileContent : this.item.content) || this.item.content);
            }
        }
    })
], default_1);
export default default_1;
